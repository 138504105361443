<template>
    <div>
        <v-row class="pr-5 pb-3 mt-5" dense>
            <v-col class="d-flex justify-content-start">
                <v-btn v-can="'Tickets.Create'" color="success" @click="add">
                    اضافة خصم
                    <i class="fa fa-plus mr-2"></i>
                </v-btn>
                <!--  -->
                <hr />
                <!--  -->
            </v-col>
        </v-row>
        <v-card width="99%" class="mx-auto mt-3 pa-5">
            <v-data-table group-by="branch.name" :headers="headers" :items="entities" fixed-header
                :server-items-length="total" :items-per-page="15" :options.sync="options" :single-expand="true"
                @item-expanded="loadDetails" :expanded.sync="expanded" show-expand :loading="loadingStates.table"
                class="elevation-1" :footer-props="{
                    showFirstLastPage: true,
                    firstIcon: 'mdi-arrow-collapse-left',
                    lastIcon: 'mdi-arrow-collapse-right',
                    prevIcon: 'mdi-plus',
                    nextIcon: 'mdi-minus',
                    'items-per-page-text': 'عدد الخصومات ' + total + '',
                }">
                <template v-slot:[`group.header`]="{ items, isOpen, toggle }">

                    <th :colspan="8" style="height: auto; border: red solid 2px !important">
                        <v-icon @click="toggle">{{ isOpen ? 'mdi-minus' : 'mdi-plus' }}
                        </v-icon>
                        {{ items[0].branch.name }}
                    </th>
                </template>
                <template v-slot:top>
                    <v-row class="mx-auto mt-3 pa-5" dark dense>
                        <v-col cols="12" sm="2">
                            <v-autocomplete dense outlined hide-details label="الفروع" :items="branches"
                                item-value="id" item-text="name" v-model="options.branchId"></v-autocomplete>
                        </v-col>
                        <v-col cols="12" sm="2">
                            <DatePicker-layout @myEvent="options.date = $event" :date.sync="options.date"
                                label="التاريخ "></DatePicker-layout>
                        </v-col>
                        <v-col cols="6" sm="2">
                            <v-btn color="error" @click="resetOptions">تفريغ
                                <i class="fa fa-trash fa-lg"></i>
                            </v-btn>
                        </v-col>
                    </v-row>
                </template>
                <!-- start expand -->
                <template v-slot:expanded-item="{ headers, item }">

                    <td :colspan="headers.length" class="pa-3">
                        <v-row class="mx-auto text-center">
                            <v-col cols="2" align="start" class="pr-5 mt-4">
                                <v-btn @click="goToPrint(item.id, item.branch.name)" color="info" class="mx-3">
                                    طباعة <v-icon>mdi-printer</v-icon>
                                </v-btn>
                            </v-col>
                            <v-col cols="12" v-if="recordsDatalist.length > 0 && item.id != null">
                                <v-card min-width="50%" class="ma-2 rounded-lg">
                                    <v-simple-table dark fixed-header height="300px">
                                        <template v-slot:default>
                                            <thead>
                                                <tr>
                                                    <th class="text-center" v-for="(item, n) in tableHeadersRecords"
                                                        :key="n">
                                                        {{ item.name }}
                                                    </th>

                                                    <th class="text-center">تفاصيل البوليصة</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr class="red--text">
                                                    <td colspan="5" class="title text-center">
                                                        {{ sumField() }} ريال
                                                    </td>
                                                </tr>
                                                <tr v-for="(item, g) in recordsDatalist" :key="g">
                                                    <td>
                                                        <div>
                                                            <div v-if="item.trackingNumber != null &&
                                                                item.trackingNumber != ' ' &&
                                                                item.trackingNumber != ''
                                                                ">
                                                                {{ item.trackingNumber }}
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div>
                                                            {{ item.discount }} ريال
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div>
                                                            {{ item.discountType | DiscountTypes }}
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div>
                                                            {{ item.discountPaidType | PaymentPaidTypes }}
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div>
                                                            {{ item.notice }}
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <v-btn color="orange" outlined elevation="2" class="mx-2"
                                                            @click="view(item.trackingNumber)" icon>
                                                            <v-icon>mdi-eye-outline</v-icon>
                                                        </v-btn>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </template>
                                    </v-simple-table>
                                </v-card>
                            </v-col>
                        </v-row>
                    </td>
                </template>
                <!-- end expand -->
                <template v-slot:[`item.createDate`]="{ item }">
                    <div v-if="item.createDate">
                        <span>
                            <v-chip style="font-size: smaller" color="green lighten-3" label>{{ item.createDate
                                |
                                ToDate
                            }}
                            </v-chip>
                        </span>
                    </div>
                </template>
            </v-data-table>
        </v-card>
        <v-dialog v-model="modals.viewShipment" width="80%" class="pa-20" ref="test" persistent>
            <v-card class="p-2">
                <ViewShipment :selected="selected" @hide="modals.viewShipment = false" ref="viewshipmentModal" />
            </v-card>
        </v-dialog>
        <v-dialog v-model="modals.addDialog" width="600" persistent>
            <v-card>
                <v-form v-model="valid">
                    <v-card-title class="headline">
                        <i class="fa fa-pen ml-2"></i>إضافة
                    </v-card-title>
                    <v-col cols="12" sm="6">
                        <v-autocomplete dense outlined :rules="[allRules.required]" v-model="selected.branchId"
                            :items="branches" item-value="id" item-text="name" auto-select-first label="الفرع">
                        </v-autocomplete>
                    </v-col>
                    <v-card-text v-for="(report, index) in reportRecordAdd" :key="index">
                        <v-container>
                            <v-row class="my-0 ammar" dense>
                                <v-col cols="12" sm="6">
                                    <v-text-field :rules="[allRules.required]" dense outlined type="text"
                                        label=" رقم البوليصة" v-model="report.trackingNumber">
                                    </v-text-field>
                                </v-col>
                                <v-col cols="12" sm="6">
                                    <v-text-field :rules="[allRules.required]" dense outlined type="text" label="قيمة الخصم"
                                        v-model="report.discount">
                                    </v-text-field>
                                </v-col>
                                <v-col cols="12" sm="6">
                                    <v-autocomplete :rules="[allRules.required]" dense outlined hide-details
                                        label="نوع الخصم" :items="enums.DiscountTypes" item-value="value" item-text="text"
                                        v-model="report.discountType"></v-autocomplete>
                                </v-col>
                                <v-col cols="12" sm="6">
                                    <v-autocomplete :rules="[allRules.required]" dense hide-details
                                        v-model="report.discountPaidType" outlined :items="enums.PaymentPaidTypes"
                                        item-text="text" item-value="value" label="طريقة الدفع"></v-autocomplete>
                                </v-col>
                            </v-row>
                            <hr />
                            <v-row class="my-0 ammar" dense>
                                <v-textarea rows="1" :rules="[allRules.required]" dense hide-details outlined
                                    name="input-7-1" label="البيان" v-model="report.notice"></v-textarea>
                            </v-row>
                            <span>
                                <i class="fas fa-minus-circle" @click="remove(index)"
                                    v-show="index || (!index && reportRecordAdd.length > 1)"></i>
                                <i class="fas fa-plus-circle" @click="addindex(index)"
                                    v-show="index == reportRecordAdd.length - 1"></i>
                            </span>
                        </v-container>
                        <v-divider></v-divider>

                    </v-card-text>

                    <v-card-actions>
                        <v-spacer></v-spacer>

                        <v-btn color="primary" :disabled="!valid" @click="editConfirmed()">
                            حفظ
                        </v-btn>

                        <v-btn color="red darken-1" text @click="cancelEdit()">
                            إلغاء
                        </v-btn>
                    </v-card-actions>
                </v-form>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import ShipmentService from "../services/ShipmentService";
import ShipmentsDiscountsService from "../services/ShipmentsDiscountsService";
import Enums from "../../../plugins/enums";
import BranchesService from "../../../features/Settings/services/BranchesService.js";
export default {
    data() {
        return {
            reportRecordAdd: [
                {
                    trackingNumber: '',
                    discount: '',
                    discountType: '',
                    notice: ''
                }
            ],
            tableHeadersRecords: [
                { name: "رقم البوليصة" },
                { name: "الخصم" },
                { name: "نوع الخصم" },
                { name: "طريقة الدفع" },
                { name: "البيان" },
            ],
            branches: [],
            recordsDatalist: [],
            expanded: [],
            singleExpand: false,
            enums: Enums,
            valid: false,
            modals: {
                viewShipment: false,
                addDialog: false,
            },
            options: {
                cityId: null,
                date: null,
            },
            selected: {},
            entities: [],
            total: 15,
            loadingStates: { table: false },
            loading: false,
        };
    },
    async created() {
        try {
            BranchesService.typeHead("", false, true).then((resp) => {
                this.branches = resp.data;
            });
            this.getData();
        } catch (error) {
            this.loading = false;
            console.log(error);
        }
    },
    methods: {
        sumField() {
            return this.recordsDatalist.reduce((a, b) => a + (b["discount"] || 0), 0);
        },
        addindex(index) {
            this.reportRecordAdd.push({
                trackingNumber: '',
                discount: '',
                discountType: '',
                notice: ''
            });
        },
        remove(index) {
            this.reportRecordAdd.splice(index, 1);
        },
        resetOptions() {
            this.options = {
                cityId: null,
                date: null,
            };
        },
        add() {
            this.selected = {};
            this.reportRecordAdd = [
                {
                    trackingNumber: '',
                    discount: '',
                    discountType: '',
                    notice: ''
                }
            ];
            this.modals.addDialog = true;
        },
        cancelEdit() {
            this.modals.addDialog = false;
            this.selected = {};
        },
        view(id) {
            if (id != "") {
                ShipmentService.getByTrackingNumberr(id)
                    .then((resp) => {
                        this.selected = resp.data;
                        this.modals.viewShipment = true;
                    })
                    .catch((resp) => { })
                    .finally(() => { });
            }
        },
        loadDetails({ item }) {
            this.recordsDatalist = [];
            ShipmentsDiscountsService.getByReportId(item.id).then((resp) => {
                this.recordsDatalist = resp.data.items;
                console.log(this.recordsDatalist);
            });
        },
        getData() {
            this.loadingStates.table = true;
            ShipmentsDiscountsService.query(this.$clean(this.options))
                .then((resp) => {
                    this.entities = resp.data.items;
                    this.total = resp.data.total;
                })
                .finally(() => {
                    this.loadingStates.table = false;
                });
        },
        editConfirmed() {
            ShipmentsDiscountsService.edit(this.selected.branchId, this.selected, this.reportRecordAdd)
                .then((resp) => {
                    this.dialog = false;
                    var message = "تم اضافه الخصم بنجاح";
                    if (!this.selected.id) {
                        this.addToArr(this.entities, resp.data);
                        message = "تم إضافة البلاغ بنجاح";
                        this.modals.addDialog = false;
                    }

                    this.$dialog.notify.success(message, {
                        position: "top-right",
                        timeout: 3000,
                    });
                })
                .catch((err) => {
                    this.dialog = false;
                    console.log(err.request.response);
                    this.$dialog.notify.error("خطأ اثناء تعديل البلاغ", {
                        position: "top-right",
                        timeout: 3000,
                    });
                });
        },
        goToPrint(id, cityname) {
            console.log(cityname);
            let routeData = this.$router.resolve({
                name: "ShipmentsDiscountsReports",
                query: {
                    reportId: id,
                    cityname: cityname,
                },
            });
            window.open(routeData.href, "_blank");
        },
    },
    watch: {
        options: {
            handler() {
                this.getData();
            },
            deep: true,
        },
    },
    computed: {
        headers() {
            let headers = [
                {
                    text: "رقم البلاغ",
                    value: "id",
                    align: "center",
                    sortable: false,
                },
                {
                    text: "التاريخ",
                    value: "createDate",
                    align: "center",
                    sortable: false,
                },
                {
                    text: "",
                    align: "center",
                    sortable: false,
                },
                {
                    text: "",
                    align: "center",
                    sortable: false,
                },
            ];

            return headers;
        },
    },
};
</script>
<style lang="scss" scoped>
#preview {
    display: flex;
    justify-content: center;
    align-items: center;
}

#preview img {
    max-width: 300px;
    max-height: 200px;
}

::v-deep .theme--light.v-list-item {
    color: rgba(0, 0, 0, 0.87) !important;
    text-align: right !important;
}

input[type="checkbox"][id^="myCheckbox"] {
    display: none;
}

label {
    border: 5px solid #fff;
    padding: 10px;
    position: relative;
    margin: 10px;
    cursor: pointer;
}

label:before {
    background-color: white;
    color: white;
    content: " ";
    display: block;
    border-radius: 50%;
    border: 1px solid grey;
    position: absolute;
    top: -5px;
    left: -5px;
    width: 25px;
    height: 25px;
    text-align: center;
    line-height: 28px;
    transition-duration: 0.4s;
    transform: scale(0);
}

label img {
    height: 40px;
    width: 100px;
    transition-duration: 0.2s;
    transform-origin: 50% 50%;
}

:checked+label {
    border-color: rgb(17, 219, 108);
}

:checked+label:before {
    content: "✓";
    background-color: grey;
    transform: scale(1);
}

:checked+label img {
    transform: scale(0.9);
    /* box-shadow: 0 0 5px #333; */
    z-index: -1;
}
</style>